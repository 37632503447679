const lookUpSeoCityName = (list: Array<any>, city: string) => {
  const flattenedList = list.flat(1);
  const indexOfCityInList = flattenedList.indexOf(city);
  return flattenedList[indexOfCityInList - 1];
};

const determineIfIDIsSEOPage = (SEO_CITY_IDS_PAGES: any, id: number) =>
  Object.keys(SEO_CITY_IDS_PAGES).includes(id.toString());

const updateInputTag = (dispatch: any, actionTypes: any, payload: string, value: string) => {
  dispatch({ type: actionTypes.CITY_SEO_PAGE, payload });
  const inputTag = document.getElementById('seo-input') as HTMLInputElement;
  inputTag.value = value;
};

export { lookUpSeoCityName, determineIfIDIsSEOPage, updateInputTag };
