import React, { useState, useRef, useContext, useEffect } from 'react';
import Image from 'next/image';
import useOutsideClick from '@hooks/useOutsideClick';
import ButtonPrimary from '@components/common/ButtonPrimary';
import searchIcon from '@assets/images/search-icon-white.svg';
import LocationResultsList from '@components/SearchBar/LocationResultsList';
import { useSearchContext } from '@context/SearchContext';
import SelectSearchInput from '@components/SearchBar/SelectSearchInput';
import { SEARCH_DEFAULT_LOCATIONS } from '@constants/SearchDefaultOptions';
import { store } from '@context/store';
import { isEmpty } from 'lodash';
import ClearButton from './ClearButton';
import DateSelector from './DateSelector';

type Props = {
  isStickyHeaderVisible: boolean;
};

export default function SearchBar({ isStickyHeaderVisible }: Props) {
  const [showDateSelector, setShowDateSelector] = useState<boolean>(false);
  const [showLocationResults, setShowLocationResults] = useState<boolean>(false);

  const {
    state: { userLocation },
  } = useContext(store);

  const { formattedDate, handleSearchClick, searchResults, setSearchResults } = useSearchContext();

  const locationRemovalRef = useRef<HTMLDivElement>(null);

  const [locationSelectionRef] = useOutsideClick(
    () => setShowLocationResults(false),
    'react-select-options-home-new__option',
    locationRemovalRef,
  );

  const [dateSelectionRef] = useOutsideClick(() => setShowDateSelector(false));

  // On click of the input, open the location search & show default locations
  const handleOnInputClick = () => {
    setShowLocationResults(true);
    setSearchResults(
      userLocation ? [userLocation, ...SEARCH_DEFAULT_LOCATIONS] : SEARCH_DEFAULT_LOCATIONS,
    );
  };

  // Scroll to search results when location results are shown
  useEffect(() => {
    if (showDateSelector || showLocationResults) {
      // Search result div is placed in homepage video to make sure it
      // scroll until the begining of the title
      const searchResultsElement = document.getElementById('search-results');

      if (searchResultsElement) {
        searchResultsElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
    return () => {
      window.onbeforeunload = () => {
        window.scrollTo(0, 0);
      };
    };
  }, [showDateSelector, showLocationResults]);

  // Hide the date selector and location results when the user scrolls past the search bar.
  useEffect(() => {
    if (isStickyHeaderVisible) {
      setShowDateSelector(false);
      setShowLocationResults(false);
    }
  }, [isStickyHeaderVisible]);

  return (
    <div id="search-bar" className="w-full text-custom-black">
      <div className="border bg-white rounded-lg z-20 flex justify-between w-full">
        <div className="flex w-full py-2">
          {/* Location Search */}
          <div className="border-r flex flex-1 flex-shrink-0 w-full justify-center px-6 font-rp-pn-regular relative">
            <div className="flex items-center relative rounded-t-md d:rounded-lg d:rounded-tr-none justify-between w-full">
              <div className="flex flex-col w-full">
                <div className="font-rp-pn-semi-bold text-sm leading-18px">
                  <span>Location</span>
                </div>

                <div className="mt-1">
                  <SelectSearchInput onClick={handleOnInputClick} />
                </div>
              </div>

              {/* Reset button */}
              <ClearButton ref={locationRemovalRef} showLocationResults={showLocationResults} />
            </div>
            {showLocationResults && !isEmpty(searchResults) ? (
              <div
                ref={locationSelectionRef}
                className="absolute bg-white max-h-392px rounded-lg shadow-search-results w-full z-20 overflow-auto pt-6 pb-8 top-70px"
              >
                <LocationResultsList
                  onResultClick={() => {
                    setShowLocationResults(false);
                  }}
                />
              </div>
            ) : null}
          </div>

          {/* Date Picker */}
          <div className="flex flex-1 flex-shrink-0 flex-col justify-center min-w-124 relative ">
            <div className="flex flex-col justify-center ml-6">
              <div className="font-rp-pn-semi-bold text-sm leading-18px">
                <span>Date</span>
              </div>

              <div
                onClick={() => setShowDateSelector(true)}
                onKeyDown={() => setShowDateSelector(true)}
                role="button"
                tabIndex={-1}
                className="cursor-pointer font-rp-pn-regular flex flex-col mt-1 font-medium justify-center text-18 leading-18px"
              >
                {formattedDate}
              </div>
            </div>

            {showDateSelector ? (
              <div
                className="absolute bg-white max-h-470px rounded-lg shadow-search-results w-full z-20 pt-6 px-9 pb-10"
                style={{ top: '70px' }}
              >
                <DateSelector setShowDateSelector={setShowDateSelector} ref={dateSelectionRef} />
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex flex-shrink-0 h-56px items-center m-2 w-148px">
          <ButtonPrimary onClick={handleSearchClick} classes="h-full space-x-2">
            <div className="flex-shrink-0 h-4 relative w-4">
              <Image src={searchIcon} alt="search-icon" layout="fill" />
            </div>

            <div className="text-18 font-rp-pn-semi-bold">Search</div>
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
}
