import React, { useEffect, useState } from 'react';
import NextImage, { StaticImageData } from 'next/image';

type SSRImageProps = {
  url: string | StaticImageData;
  classes?: string;
  [x: string]: any;
};

export default function ImageLoaded({ url, classes, ...rest }: SSRImageProps) {
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement | StaticImageData>();

  useEffect(() => {
    if (typeof url === 'string') {
      const image = new Image();
      image.src = url;
      image.onload = () => setLoadedImage(image);
    } else {
      setLoadedImage(url);
    }
  }, [url]);

  return loadedImage ? <NextImage src={loadedImage} className={classes} {...rest} /> : null;
}

ImageLoaded.defaultProps = {
  classes: '',
};
