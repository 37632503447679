import { SeoCity } from '@customTypes/seoCityPages';

/**
 * @param listOfSeoCities array consisting of city page names and ids
 * @returns object of city pages with key being the id and value being the name
 */
const parseCityNamesAndIds = (listOfSeoCities: Array<string>) => {
  const cities: SeoCity = {};
  listOfSeoCities.forEach((city: string) => {
    const citySplit = city.split('-');
    const id = citySplit[citySplit.length - 1];
    const name = citySplit
      .filter((el: any) => !/^-?\d+\.?\d*$/.test(el))
      .toString()
      .replace(',', ' ');
    cities[id] = [city, name];
  });
  return cities;
};

export default parseCityNamesAndIds;
