import parseCityNamesAndIds from '@helpers/parseCityNamesAndIds';
import { determineIfIDIsSEOPage } from '@helpers/seoCityPages';

const isRouteSearchBarEnabled = (route: string, query: any = {}) => {
  const searchBarEnabledRoutes = ['hotel-day-passes', 'results'];
  const result = searchBarEnabledRoutes.some((r) => route.includes(r));
  if (!(query.cityDetails && route.includes('cities'))) return result;
  // -------------------------------------------------------------------
  // Search Bar Enabled for City Pages ---------------------------------
  // -------------------------------------------------------------------
  // - Include search for city pages
  // - Exclude search for SEO pages
  //     (code for SEOpage determination copied from [cityDetails]
  // -------------------------------------------------------------------
  const cityId = +query.cityDetails.split('-').pop();
  if (cityId) {
    const SEO_CITY_IDS_PAGES =
      (process.env &&
        process.env.NEXT_PUBLIC_SEO_CITY_IDS_PAGES &&
        parseCityNamesAndIds(process.env.NEXT_PUBLIC_SEO_CITY_IDS_PAGES.split(','))) ||
      {};
    const isSEOPage = determineIfIDIsSEOPage(SEO_CITY_IDS_PAGES, cityId);
    return !isSEOPage;
  }
  return result;
};

export default isRouteSearchBarEnabled;
